export const landing_pages_entities_type = {
  ROUTE: "route",
  POINT_OF_INTEREST_ROUTE: "point-of-interest-route",
  MULTIMODAL_ROUTE: "multimodal-route",
  POINT_OF_INTEREST: "point-of-interest",
  TRAIN_ROUTE: "train-route",
  PARTNER: "partner",
  BUS_TICKETS_PAGE: "bus-tickets-page",
  TRAIN_TICKETS_PAGE: "train-tickets-page",
  COUNTRY: "country",
  STOP: "stop",
  CITY: "city",
  TRAINS_TO_PAGE: "trains-to",
  HOME: "home",
  SERVICE_DISRUPTION: "service-disruption",
  SEASONAL_PAGE: "seasonal-page",
  SITEMAP_ROUTES_CITY: "sitemap-routes-city",
  SITEMAP_ROUTES_COUNTRY: "sitemap-routes-country",
  SITEMAP_ROUTES_INDEX: "sitemap-routes-index",
  SITEMAP_STOPS_CITY: "sitemap-stops-city",
  SITEMAP_STOPS_COUNTRY: "sitemap-stops-country",
  SITEMAP_STOPS_INDEX: "sitemap-stops-index",
  SITEMAP_POI_INDEX: "sitemap-points-of-interest-index",
  SITEMAP_POI_COUNTRY: "sitemap-points-of-interest-country",
  SITEMAP_POI_CITY: "sitemap-points-of-interest-city",
  SITEMAP_CITIES_COUNTRY: "sitemap-cities-country",
  SITEMAP_CITIES_INDEX: "sitemap-cities-index",
  SITEMAP_TRAINS_TO_COUNTRY: "sitemap-trains-to-country",
  SITEMAP_TRAINS_TO_INDEX: "sitemap-trains-to-index",
  SITEMAP_PARTNERS_INDEX: "sitemap-partners-index",
  SITEMAP_SERVICE_DISRUPTIONS_INDEX: "sitemap-service-disruptions-index",
  SEM_ROUTE_PAGE: "sem-route-page",
  SEM_CITY_PAGE: "sem-city-page"
} as const;

type LandingPagesEntitiesType = typeof landing_pages_entities_type;

export const landing_pages_entities_trains_types = [
  "train-route",
  "trains-to",
  "train-tickets-page"
] as const satisfies Array<
  LandingPagesEntitiesType[keyof LandingPagesEntitiesType]
>;
