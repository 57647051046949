export const AUTOCOMPLETE_DEBOUNCE_MS = 150;
export const AUTOCOMPLETE_SPINNER_DELAY_MS = 200;
export const AUTOCOMPLETE_SPINNER_MIN_DURATION_MS = 150;

export const SEARCH_ERRORS = {
  MISSING_ORIGIN: "missing_origin",
  MISSING_DESTINATION: "missing_destination",
  MISSING_OUTBOUND: "missing_outbound",
  RETURN_BEFORE_OUTBOUND: "return_before_outbound",
  CHILD_ALONE: "child_alone",
  MISSING_AGE: "missing_age"
} as const;

export const MAX_SEARCH_DISTANCE_MONTHS = 6;

export const VALIDATE_FORM_MESSAGE = "VALIDATE_SEARCH_FORM";
