import React from "react";

import { Control } from "react-hook-form";

import { useDevTools } from "@app/utils/hooks/use-devtools";

const DevTool = React.lazy(() =>
  import("@hookform/devtools").then(module => ({ default: module.DevTool }))
);

interface Props {
  control: Control<any, any>;
}

export const SearchFormDevTools: React.FC<Props> = ({ control }) => {
  const { enabled } = useDevTools();

  if (!enabled) {
    return null;
  }

  return (
    <React.Suspense fallback={null}>
      <DevTool control={control} />
    </React.Suspense>
  );
};
