import { SEARCH_TYPES } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/flex-query-params";
import { getTracker } from "@app/store/middlewares/utils";
import { invalidCityError } from "@app/tracking/search-tracking";
import { LandingPageReduxState } from "@app/types/landing-page";
import { ResultsReduxState } from "@app/types/results-redux-types";
import {
  SearchFormAutocompleteType,
  SearchReduxState,
  SearchReduxStateWithRoute
} from "@app/types/search-types";
import { InvalidCityError } from "@app/types/tracking/search";

export const getGlobalSearchFormState = ():
  | SearchReduxState
  | SearchReduxStateWithRoute => {
  if (typeof window === "undefined") {
    throw new Error("This function should only be called on the client side");
  }

  return (window.store.getState() as LandingPageReduxState | ResultsReduxState)
    .search_form;
};

interface InvalidCityErrorContext {
  query: string;
  search_types: SearchFormAutocompleteType[];
  longitude: string | null | undefined;
  latitude: string | null | undefined;
}

export const trackInvalidCityError = ({
  query,
  search_types,
  latitude,
  longitude
}: InvalidCityErrorContext): void => {
  const { origin } = getGlobalSearchFormState();
  const tracker = getTracker();

  const error: InvalidCityError = { search_types: [] };

  const error_context = search_types.reduce((error_context, search_type) => {
    error_context.search_types.push(search_type);

    switch (search_type) {
      case SEARCH_TYPES.ORGANIC:
        if (query.length > 2) {
          error_context.characters_entered = query;
        }
        break;
      case SEARCH_TYPES.POPULAR:
        error_context.origin_city_id = origin?.city.id;
        break;
      case SEARCH_TYPES.NEARBY:
        error_context.latitude = latitude;
        error_context.longitude = longitude;
        break;
      default:
        break;
    }

    return error_context;
  }, error);

  tracker.track(invalidCityError(error_context));
};
