import React, { PropsWithChildren } from "react";

import MenuItem from "@busbud/design-system-components/dist/MenuItem";

import { useSuggestionTemplateServerStyles } from "@app/modules/search/suggestion/suggestion-template-server.styles";

type SuggestionPlace = "city" | "location" | "point-of-interest";

interface WrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  type: SuggestionPlace;
}

export const SuggestionWrapper: React.FC<PropsWithChildren<WrapperProps>> = ({
  children,
  className,
  ...props
}) => {
  const { cx, classes } = useSuggestionTemplateServerStyles();

  return (
    <MenuItem
      className={cx(
        classes.root,
        {
          [classes.city]: props.type === "city",
          [classes.location]: props.type === "location"
        },
        className
      )}
      component="div"
      {...props}
    >
      {children}
    </MenuItem>
  );
};
