import React from "react";

import { useEventListener } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-event-listener";

export const useClickOutside = (
  ref: React.RefObject<HTMLDivElement>,
  cb: (e: Event) => void
) => {
  useEventListener("mousedown", e => {
    if (
      !ref.current ||
      !(e.target instanceof Element) ||
      ref.current.contains(e.target)
    ) {
      return;
    }
    cb(e);
  });
};
