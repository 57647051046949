import { LocationStateValues } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-search-state-hooks";
import { Features } from "@app/types/experiments";
import {
  SearchFormAutocompleteType,
  SearchFormQueryParams,
  SearchLocationType,
  SearchPlace
} from "@app/types/search-types";

import { SupportedLocale } from "@config/locales";

const SEARCH_RESULTS_LIMIT = 5;
const SEARCH_RESULTS_LIMIT_INCREASED = 8;

const MAX_POPULAR_SUGGESTIONS = 4;
const MAX_NEARBY_SUGGESTIONS = 4;
const MIN_ORGANIC_SEARCH_LENGTH = 2;

export const SEARCH_TYPES = {
  POPULAR: "popular",
  ORGANIC: "organic",
  NEARBY: "nearby",
  INVALID: "invalid"
} as const satisfies Record<string, SearchFormAutocompleteType>;

interface SearchTypesParams {
  query: string;
  input_type: SearchLocationType;
  search_state: Pick<LocationStateValues, "origin" | "destination">;
  is_geo_available: boolean;
  features: Partial<Features>;
}

export function getSearchTypes({
  query,
  input_type,
  search_state: { origin, destination },
  is_geo_available
}: SearchTypesParams): SearchFormAutocompleteType[] {
  const is_origin_autocomplete = input_type === "origin";
  const is_destination_autocomplete = input_type === "destination";

  if (!query || query.length < MIN_ORGANIC_SEARCH_LENGTH) {
    if (is_origin_autocomplete) {
      if (destination) {
        return [SEARCH_TYPES.POPULAR];
      } else if (is_geo_available) {
        return [SEARCH_TYPES.NEARBY];
      }
    }
    if (is_destination_autocomplete) {
      if (origin) {
        return [SEARCH_TYPES.POPULAR];
      } else if (is_geo_available) {
        return [SEARCH_TYPES.NEARBY];
      }
    }

    return [SEARCH_TYPES.INVALID];
  }

  if (query.length >= MIN_ORGANIC_SEARCH_LENGTH) {
    if (is_origin_autocomplete && is_geo_available) {
      return [SEARCH_TYPES.NEARBY, SEARCH_TYPES.ORGANIC];
    }

    return [SEARCH_TYPES.ORGANIC];
  }

  return [SEARCH_TYPES.INVALID];
}

interface SearchTypesQueryParamParams {
  search_type: SearchFormAutocompleteType;
  input_type: SearchLocationType;
  search_place: SearchPlace | null;
  query: string;
  features: Partial<Features>;
  latitude: string | null | undefined;
  longitude: string | null | undefined;
}

export function getOriginPlaceIds(
  search_place: SearchPlace | null
): Record<"origin_id" | "origin_child_id", string | null | undefined> {
  let origin_id: string | null | undefined =
    search_place && search_place?.city.id;
  const origin_child_id: string | null | undefined =
    search_place && search_place.location && search_place.location.id[0];

  if (search_place?.request_id) {
    // Append request_id to url bc Flex uses request id to track Search by Location metrics
    origin_id = `${search_place.request_id}:${origin_id}`;
  }

  return { origin_id, origin_child_id };
}

export function getSearchTypesQueryParam({
  search_type,
  input_type,
  search_place,
  query,
  features,
  latitude,
  longitude
}: SearchTypesQueryParamParams) {
  const is_origin_autocomplete = input_type === "origin";

  const { origin_id, origin_child_id } = getOriginPlaceIds(search_place);

  switch (search_type) {
    case SEARCH_TYPES.NEARBY:
      return {
        lat: latitude,
        lon: longitude,
        limit: MAX_NEARBY_SUGGESTIONS
      };

    case SEARCH_TYPES.ORGANIC: {
      return {
        q: query,
        origin_id: !is_origin_autocomplete ? origin_id : undefined,
        origin_child_id: !is_origin_autocomplete ? origin_child_id : undefined,
        limit: features.AUTO_COMPLETE_SUGGESTIONS_INCREASE
          ? SEARCH_RESULTS_LIMIT_INCREASED
          : SEARCH_RESULTS_LIMIT
      };
    }
    case SEARCH_TYPES.POPULAR:
      return {
        origin_id,
        origin_child_id,
        limit: MAX_POPULAR_SUGGESTIONS
      };

    default:
      return {};
  }
}

interface NapiQueryParamsParams {
  query: string;
  input_type: SearchLocationType;
  search_types: SearchFormAutocompleteType[];
  search_state: Pick<LocationStateValues, "origin" | "destination">;
  lang: string;
  locale: SupportedLocale;
  longitude: string | null | undefined;
  latitude: string | null | undefined;
  features: Partial<Features>;
}

export function getNapiQueryParams({
  search_types,
  query,
  search_state: { origin, destination },
  input_type,
  locale,
  lang,
  longitude,
  latitude,
  features
}: NapiQueryParamsParams): SearchFormQueryParams {
  const is_origin_autocomplete = input_type === "origin";

  const search_place = is_origin_autocomplete ? destination : origin;

  const napi_query_params = search_types.reduce((query_params, search_type) => {
    return {
      ...query_params,
      ...getSearchTypesQueryParam({
        search_type,
        input_type,
        search_place,
        query,
        features,
        longitude,
        latitude
      })
    };
  }, {});

  return { ...napi_query_params, lang, locale };
}

export function isSearchTypeInvalid(
  search_types: SearchFormAutocompleteType[]
): boolean {
  return search_types.includes(SEARCH_TYPES.INVALID);
}

export function getFormattedQueryParams(
  params: SearchFormQueryParams
): string[] {
  return Object.keys(params)
    .filter(
      k =>
        params[k as keyof typeof params] !== null &&
        typeof params[k as keyof typeof params] !== "undefined"
    )
    .map(
      k =>
        `${encodeURIComponent(k)}=${encodeURIComponent(
          params[k as keyof typeof params] ?? ""
        )}`
    );
}
