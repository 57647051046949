import { combineReducers } from "redux";

import affiliateOptinReducer from "./affiliate-optin";
import destinationReducer from "./destination";
import originReducer from "./origin";
import outboundDateReducer from "./outbound-date";
import passengersReducer from "./passengers";
import recentSearches from "./recent-searches";
import returnDateReducer from "./return-date";

export * from "./affiliate-optin";
export * from "./destination";
export * from "./origin";
export * from "./outbound-date";
export * from "./passengers";
export * from "./recent-searches";
export * from "./return-date";

export default combineReducers({
  affiliate_optin: affiliateOptinReducer,
  destination: destinationReducer,
  origin: originReducer,
  outbound_date: outboundDateReducer,
  passengers: passengersReducer,
  recent_searches: recentSearches,
  return_date: returnDateReducer
});
