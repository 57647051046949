import React from "react";

import Label from "@busbud/design-system-components/dist/Label";

import { getSuggestionLocationIcon } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-option/autocomplete-icons";
import { SuggestionWrapper } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-option/suggestion-wrapper";
import { NapiFlexLocationOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";
import {
  SuggestionIcon,
  SuggestionIconType
} from "@app/modules/search/suggestion/suggestion-icon";
import { useSuggestionTemplateServerStyles } from "@app/modules/search/suggestion/suggestion-template-server.styles";

interface LocationSuggestionItemProps
  extends React.HTMLAttributes<HTMLDivElement> {
  suggestion: NapiFlexLocationOption;
}

export const LocationSuggestionItem: React.FC<LocationSuggestionItemProps> =
  React.memo(({ suggestion, ...props }) => {
    const { classes } = useSuggestionTemplateServerStyles();
    const { location } = suggestion.value;
    const icon: SuggestionIconType = getSuggestionLocationIcon(
      location?.stop_type
    );

    return (
      <SuggestionWrapper type="location" {...props}>
        <SuggestionIcon size="sm" type={icon} hidden={false} />
        <div className={classes.textContainer}>
          <div className={classes.locationLabel}>
            <Label
              color="text.primary.opaque"
              size="md"
              className={classes.locationLabel}
            >
              {location?.full_name}
            </Label>
          </div>
        </div>
      </SuggestionWrapper>
    );
  });
