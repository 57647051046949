import { useCallback, useRef, useState } from "react";

import { getSearchQueryValue } from "@app/components/search-form/helpers";
import { useDebouncedValue } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-debounced-value";
import { NapiFlexLocationOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";
import { AUTOCOMPLETE_DEBOUNCE_MS } from "@app/modules/search/constants";

type InputMode = "typing" | "selected" | "hovered";

export const useSearchQueryValue = (input_value: string) => {
  const [query, setQuery] = useState(input_value);
  const last_query_ref = useRef<string>(query);
  const deferred_query_ref = useRef<string | null>(null);

  const [debounced_query] = useDebouncedValue(query, AUTOCOMPLETE_DEBOUNCE_MS);

  const updateQuery = useCallback(
    (new_value: string | NapiFlexLocationOption, mode: InputMode) => {
      if (last_query_ref.current === new_value) {
        return;
      }

      // Updated by typing
      if (typeof new_value === "string") {
        // Empty string or 2+ characters are required for valid queries
        const is_valid_flex_query: boolean = new_value.length !== 1;
        if (is_valid_flex_query) {
          last_query_ref.current = new_value;
          setQuery(new_value);
        }
        return;
      }

      // Updated by selecting an option
      const formatted_value = getSearchQueryValue(new_value.value);

      if (mode === "hovered") {
        deferred_query_ref.current = formatted_value;
        return;
      }

      last_query_ref.current = new_value.label;
      setQuery(formatted_value);
    },
    []
  );

  const updateOnFocus = useCallback((new_value: string) => {
    if (last_query_ref.current === new_value) {
      return;
    }

    if (deferred_query_ref.current) {
      setQuery(deferred_query_ref.current);
      deferred_query_ref.current = null;
      last_query_ref.current = new_value;
    }

    setQuery(new_value);
    last_query_ref.current = new_value;
  }, []);

  return {
    query,
    debounced_query,
    updateQuery,
    updateOnFocus
  };
};
