import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";

import { SearchReduxState } from "@app/types/search-types";

const setAffiliateOptinReducer: CaseReducer<
  SearchReduxState["affiliate_optin"],
  PayloadAction<SearchReduxState["affiliate_optin"]>
> = (_state, { payload }) => payload;

const initial_state: SearchReduxState["affiliate_optin"] = true;

const AffiliateOptin = createSlice({
  name: "search_form/affiliate_optin",
  initialState: initial_state,
  reducers: {
    setAffiliateOptin: setAffiliateOptinReducer
  }
});

export const { setAffiliateOptin } = AffiliateOptin.actions;
export default AffiliateOptin.reducer;
