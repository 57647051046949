import React, { FC, PropsWithChildren } from "react";

import { mergeClasses } from "@busbud/horizon";

export interface DialogBodyProps {
  as?: "blockquote" | "div" | "li" | "section" | "ul";
  className?: string;
}

export const DialogBody: FC<PropsWithChildren<DialogBodyProps>> = ({
  as: Component = "div",
  className,
  ...restProps
}) => (
  <Component
    className={mergeClasses("px-300 pb-350 pt-300", className)}
    {...restProps}
  />
);
