import React from "react";

import { getCompactConfiguration } from "@app/components/search-form/helpers";
import type {
  Props,
  SearchFormStyleVariant,
  SearchFormVariant
} from "@app/components/search-form/search-form";
import { SearchFormHydrated } from "@app/components/search-form-hydrated/search-form-hydrated";
import { useLiteAppContext } from "@app/helpers/hooks";

const SEARCH_FORM_ID = "search-form";

/**
 * SearchFormClient is a client-side only component that hydrates the server-rendered SearchForm.
 * This component is required to avoid hydration mismatches.
 *
 * @param props
 * @param props.affiliate_checked
 * @param props.with_affiliate_checkbox
 */
export const SearchFormClient: React.FC<
  Omit<Props, "variant" | "style_variant">
> = ({ affiliate_checked, with_affiliate_checkbox = false, ...props }) => {
  const { liteTranslator } = useLiteAppContext();

  if (typeof window === "undefined") {
    throw new Error("SearchFormClient should only be used on the client side");
  }

  // Get the variant data from the server-rendered SearchForm
  const { variant, style_variant, one_way_only } = React.useMemo(() => {
    const search_form = document.getElementById(SEARCH_FORM_ID);
    if (!search_form) {
      throw new Error("SearchForm was not rendered on the server");
    }
    const data_variant = search_form.getAttribute("data-variant");
    if (!data_variant) {
      throw new Error(
        "SearchForm was not rendered with variant data attribute"
      );
    }
    const data_style_variant =
      search_form.getAttribute("data-style-variant") || undefined;
    const data_one_way_only = search_form.getAttribute("data-one-way-only");
    return {
      variant: data_variant as SearchFormVariant,
      style_variant: data_style_variant as SearchFormStyleVariant | undefined,
      one_way_only: data_one_way_only === "true"
    };
  }, []);

  const search_labels: Record<SearchFormVariant, string> = {
    "landing-page": liteTranslator.t("!landing.input-label.search"),
    results: liteTranslator.t("!results.search.submit"),
    "results-menu": liteTranslator.t("!results.search.submit")
  };

  const search_label = search_labels[variant];

  const compact_configuration = React.useMemo(
    () => getCompactConfiguration(variant),
    [variant]
  );

  return (
    <SearchFormHydrated
      data-variant={variant}
      data-style-variant={style_variant}
      data-one-way-only={Boolean(one_way_only)}
      with_affiliate_checkbox={with_affiliate_checkbox}
      affiliate_checked={affiliate_checked}
      search_label={search_label}
      compact_configuration={compact_configuration}
      style_variant={style_variant}
      one_way_only={one_way_only}
      {...props}
    />
  );
};
