import React, { PropsWithChildren } from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";

import ExchangeMD from "@busbud/design-system-components/dist/Icons/Exchange/MD";
import { ResponsiveProp } from "@busbud/design-system-components/dist/utilities/responsiveProps";
import { UnstyledButton } from "@busbud/horizon";

import { TwCommonHeaderStyles } from "@app/components/header/header.styles";
import { AffiliateCheckbox } from "@app/components/landing-pages/affiliate-checkbox";
import { DatePassengerSearchGroup } from "@app/components/search-form/date-passenger-search-group";
import {
  CompactConfiguration,
  getCompactConfiguration
} from "@app/components/search-form/helpers";
import { LocationGroup } from "@app/components/search-form/location-group";
import { SearchFormButton } from "@app/components/search-form/search-form-button";
import { SearchFormHydrated } from "@app/components/search-form-hydrated/search-form-hydrated";
import { ReactQueryProvider } from "@app/context/react-query-provider";
import { isHydratedSearchForm } from "@app/controllers/helpers/search/get-search-form-variant";
import { useAppContext, useLiteAppContext } from "@app/helpers/hooks";
import { LandingPageReduxState } from "@app/types/landing-page";
import { ResultsReduxState } from "@app/types/results-redux-types";
import { SearchReduxState } from "@app/types/search-types";

import { AutocompleteInput } from "./autocomplete-input";
import { DatepickerInput } from "./datepicker-input";
import { PassengerInfoInput } from "./passenger-info-input";

export type SearchFormVariant = "landing-page" | "results" | "results-menu";
export type SearchFormStyleVariant =
  | "default"
  | "collapse"
  | "full_width"
  | "form_root"
  | "form_root_date_tabs";

export interface Props {
  variant: SearchFormVariant;
  style_variant?: SearchFormStyleVariant;
  one_way_only?: boolean;
  with_affiliate_checkbox?: boolean;
  affiliate_checked?: boolean;
}

const search_form_classes = {
  container:
    "group flex flex-wrap lg:rounded-md lg:bg-color-canvas-primary lg:shadow-md"
} satisfies Record<string, string>;

const SEARCH_FORM_ID = "search-form";

interface SearchFormContainerProps
  extends PropsWithChildren<
    React.DetailedHTMLProps<
      React.FormHTMLAttributes<HTMLFormElement>,
      HTMLFormElement
    >
  > {
  style_variant?: SearchFormStyleVariant;
}

const search_form_container_styles: Record<
  SearchFormStyleVariant,
  Parameters<typeof clsx>
> = {
  default: [],
  collapse: [
    "transition-all duration-500 ease-in-out",
    "group-[.is-expanded]:mb-200 group-[.is-expanded]:mt-100 group-[.is-expanded]:flex group-[.is-expanded]:max-h-[400px] group-[.is-expanded]:opacity-100",
    "group-[.is-collapsed]:max-h-0 group-[.is-collapsed]:overflow-hidden group-[.is-collapsed]:opacity-0"
  ],
  form_root: [TwCommonHeaderStyles.formRoot],
  form_root_date_tabs: [
    TwCommonHeaderStyles.formRoot,
    TwCommonHeaderStyles.showDateTabs
  ],
  full_width: ["w-full"]
};

export const SearchFormContainer: React.FC<SearchFormContainerProps> = ({
  children,
  className,
  style_variant = "default",
  ...props
}) => {
  const style_classes = search_form_container_styles[style_variant];

  return (
    <form
      id={SEARCH_FORM_ID}
      noValidate
      className={clsx(search_form_classes.container, style_classes, className)}
      {...props}
    >
      {children}
    </form>
  );
};

interface SearchFormLegacyProps extends Omit<Props, "variant"> {
  search_label: string;
  compact_configuration: CompactConfiguration;
}

const SearchFormLegacy: React.FC<SearchFormLegacyProps> = ({
  one_way_only = false,
  search_label,
  compact_configuration,
  ...props
}) => {
  const { entity, features, translator } = useAppContext();
  const search_form_redux_state: SearchReduxState = useSelector(
    (state: LandingPageReduxState | ResultsReduxState) => state.search_form
  );

  const { input_size } = toSizes(compact_configuration);

  const is_results = !!(entity && entity.type === "results");
  const is_disabled =
    is_results && features.SEARCH_FORM_DISABLE_LOCATION_INPUTS_ON_RESULTS;

  return (
    <SearchFormContainer data-testid={SEARCH_FORM_ID} {...props}>
      <LocationGroup
        one_way_only={one_way_only}
        originInputSection={
          <AutocompleteInput
            type="origin"
            size={input_size}
            state={search_form_redux_state}
            is_disabled={is_disabled}
          />
        }
        swapLocationSection={
          <UnstyledButton
            role="button" // TODO: Remove role after fix in horizon
            id="swap-cities-icon"
            className={clsx(
              "border rotate-45 rounded-md border-width-sm border-color-primary bg-color-canvas-primary p-100 sm:border-color-static-transparent sm:p-075",
              {
                "active:bg-color-canvas-secondary sm:hover:border-color-primary":
                  !is_disabled
              }
            )}
            ariaLabel={translator.t("!landing.input-label.swap-locations")}
          >
            <ExchangeMD
              color={
                is_disabled ? "icon.secondary.opaque" : "icon.primary.opaque"
              }
              className="rotate-45 sm:-rotate-45"
            />
          </UnstyledButton>
        }
        destinationInputSection={
          <AutocompleteInput
            type="destination"
            size={input_size}
            state={search_form_redux_state}
            is_disabled={is_disabled}
          />
        }
      />
      <DatePassengerSearchGroup
        one_way_only={one_way_only}
        outboundDateInputSection={
          <DatepickerInput
            type="outbound"
            size={input_size}
            date={search_form_redux_state.outbound_date || undefined}
          />
        }
        returnDateInputSection={
          <DatepickerInput
            type="return"
            size={input_size}
            date={search_form_redux_state.return_date || undefined}
          />
        }
        passengerInfoSection={<PassengerInfoInput size={input_size} />}
        searchButtonSection={
          <SearchFormButton
            label={search_label}
            compact={compact_configuration}
          />
        }
      />
    </SearchFormContainer>
  );
};

function toSizes(compact: CompactConfiguration) {
  const compact_keys = Object.keys(compact) as (keyof CompactConfiguration)[];
  return {
    input_size: compact_keys.reduce<ResponsiveProp<"sm" | "md">>(
      (obj, breakpoint_key) => {
        return Object.assign(obj, {
          [breakpoint_key]: compact[breakpoint_key] ? "sm" : "md"
        });
      },
      {}
    )
  };
}

const ServerWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const is_server = typeof window === "undefined";

  if (!is_server) {
    return children;
  }

  return (
    <div
      // This wrapper div is used for React root hydration
      id="header-search"
    >
      <ReactQueryProvider>{children}</ReactQueryProvider>
    </div>
  );
};

export const SearchForm: React.FC<Props> = ({
  affiliate_checked,
  with_affiliate_checkbox = false,
  variant,
  style_variant,
  one_way_only,
  ...props
}) => {
  const { features, liteTranslator } = useLiteAppContext();
  const is_new_search_form_enabled = isHydratedSearchForm(features);

  const search_labels: Record<SearchFormVariant, string> = {
    "landing-page": liteTranslator.t("!landing.input-label.search"),
    results: liteTranslator.t("!results.search.submit"),
    "results-menu": liteTranslator.t("!results.search.submit")
  };

  const search_label = search_labels[variant];

  const compact_configuration = React.useMemo(
    () => getCompactConfiguration(variant),
    [variant]
  );

  if (is_new_search_form_enabled) {
    return (
      <ServerWrapper>
        <SearchFormHydrated
          data-variant={variant}
          data-style-variant={style_variant}
          data-one-way-only={Boolean(one_way_only)}
          with_affiliate_checkbox={with_affiliate_checkbox}
          affiliate_checked={affiliate_checked}
          search_label={search_label}
          compact_configuration={compact_configuration}
          style_variant={style_variant}
          one_way_only={one_way_only}
          {...props}
        />
      </ServerWrapper>
    );
  }

  return (
    <>
      <SearchFormLegacy
        search_label={search_label}
        compact_configuration={compact_configuration}
        style_variant={style_variant}
        one_way_only={one_way_only}
        {...props}
      />
      {!!with_affiliate_checkbox && (
        <AffiliateCheckbox isDefaultChecked={affiliate_checked} />
      )}
    </>
  );
};
