import React from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { useDevTools } from "@app/utils/hooks/use-devtools";

const query_client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  }
});

interface Props {
  children: React.ReactNode;
}

export const ReactQueryProvider: React.FC<Props> = ({ children }) => {
  const { enabled: devtools_enabled } = useDevTools();

  return (
    <QueryClientProvider client={query_client}>
      {children}
      {!!devtools_enabled && <ReactQueryDevtools initialIsOpen />}
    </QueryClientProvider>
  );
};
