import React from "react";

import {
  Button,
  ButtonProps,
  ButtonIcon,
  IconMagnifyingGlass
} from "@busbud/horizon";

import { useEntity } from "@app/context/use-entity";
import { useLiteAppContext } from "@app/helpers/hooks";

type CompactConfiguration = Partial<
  Record<"xs" | "sm" | "md" | "lg" | "xl", boolean>
>;

interface SearchFormButtonProps {
  label: string;
  isSubmit?: boolean;
  compact?: CompactConfiguration;
}

const results_entity: Record<string, ButtonProps> = {
  mobile: {
    size: "lg",
    appearance: "primary",
    iconStart: <IconMagnifyingGlass size="md" />
  },
  tablet: {
    appearance: "primary"
  },
  desktop: {
    size: {
      initial: "lg",
      xl: "xl"
    },
    appearance: "primary",
    iconStart: <IconMagnifyingGlass size="lg" />
  }
} as const;

const default_entity: Record<string, ButtonProps> = {
  mobile: {
    size: "lg",
    appearance: "primary",
    iconStart: <IconMagnifyingGlass size="md" />
  },
  tablet: {
    size: "xl",
    appearance: "primary"
  },
  desktop: {
    size: "xl",
    appearance: "primary",
    iconStart: <IconMagnifyingGlass size="lg" />
  }
} as const;

export const SearchFormButton = ({
  label,
  compact,
  isSubmit
}: SearchFormButtonProps) => {
  const { features, liteTranslator } = useLiteAppContext();
  const { button_size } = toSizes(compact ?? {});
  const entity = useEntity();

  const button_title = liteTranslator.t("!landing.input-label.search");

  if (features.DYNAMIC_SEARCH_BUTTON) {
    results_entity.mobile.appearance = "default";
    delete results_entity.tablet.appearance;
    results_entity.tablet.variant = "text";
    delete results_entity.desktop.appearance;
    results_entity.desktop.variant = "text";
  }

  const type = entity?.type === "results" ? results_entity : default_entity;

  return (
    <>
      <span className="h-full sm:hidden">
        <Button
          data-testid="submit-button"
          className="h-full w-full"
          ariaLabel={button_title}
          isSubmit={isSubmit}
          {...type.mobile}
        >
          {label ?? button_title}
        </Button>
      </span>
      <span className="hidden h-full sm:inline md:hidden">
        <ButtonIcon
          data-testid="submit-iconbutton"
          ariaLabel={button_title}
          className="h-full w-full rounded-bl-none rounded-tl-none"
          size={button_size}
          isSubmit={isSubmit}
          {...type.tablet}
        >
          <IconMagnifyingGlass size="lg" />
        </ButtonIcon>
      </span>
      <span className="hidden h-full md:block">
        <Button
          data-testid="submit-button"
          className="h-full w-full rounded-bl-none rounded-tl-none xl:rounded-bl-none xl:rounded-tl-none"
          ariaLabel={button_title}
          isSubmit={isSubmit}
          {...type.desktop}
        >
          {label ?? button_title}
        </Button>
      </span>
    </>
  );
};

// convert compactConfiguration to buttonProps.size
function toSizes(compact: CompactConfiguration): {
  button_size: ButtonProps["size"];
} {
  return {
    button_size: {
      initial: "lg",
      sm: compact.sm ? "lg" : "xl",
      md: compact.md ? "lg" : "xl",
      lg: compact.lg ? "lg" : "xl",
      xl: compact.xl ? "lg" : "xl"
    }
  };
}
