import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SearchReduxState } from "@app/types/search-types";

const setOriginReducer = (
  _state: SearchReduxState["origin"],
  action: PayloadAction<SearchReduxState["origin"]>
) => action.payload;

const initial_state = null as SearchReduxState["origin"];

const Origin = createSlice({
  name: "search_form/origin",
  initialState: initial_state,
  reducers: {
    setOrigin: setOriginReducer
  }
});

export const { setOrigin } = Origin.actions;
export default Origin.reducer;
