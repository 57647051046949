import React from "react";

import Label from "@busbud/design-system-components/dist/Label";

import { getSuggestionLocationIcon } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-option/autocomplete-icons";
import { SuggestionWrapper } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-option/suggestion-wrapper";
import { NapiFlexLocationOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";
import {
  SuggestionIcon,
  SuggestionIconType
} from "@app/modules/search/suggestion/suggestion-icon";
import { useSuggestionTemplateServerStyles } from "@app/modules/search/suggestion/suggestion-template-server.styles";

interface CitySuggestionItemProps extends React.HTMLAttributes<HTMLDivElement> {
  suggestion: NapiFlexLocationOption;
}

export const CitySuggestionItem: React.FC<CitySuggestionItemProps> = React.memo(
  ({ suggestion, ...props }) => {
    const { classes } = useSuggestionTemplateServerStyles();
    const { full_name, location } = suggestion.value;

    const [city_name, ...region_name] = full_name.split(",");

    let icon: SuggestionIconType = "city";

    if (location) {
      icon = getSuggestionLocationIcon(location.stop_type);
    }

    return (
      <SuggestionWrapper type="city" {...props}>
        <SuggestionIcon size="md" type={icon} hidden={false} />
        <div className={classes.textContainer}>
          <div className={classes.cityRegionLabel}>
            <Label color="text.primary.opaque" fontWeight="bold" size="lg">
              {city_name}
            </Label>
            <Label
              color="text.tertiary.opaque"
              size="md"
              className={classes.regionLabel}
            >
              {region_name.join(", ")}
            </Label>
          </div>
          {!!location && (
            <Label
              color="text.primary.opaque"
              size="md"
              className={classes.detailLabel}
            >
              {location.full_name}
            </Label>
          )}
        </div>
      </SuggestionWrapper>
    );
  }
);
