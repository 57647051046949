import { proxy, ref } from "valtio";
import { useSnapshot } from "valtio/react";

type ModalOption = "origin" | "destination" | null;

interface LocationModalState {
  open_input: ModalOption;
  input_refs: {
    origin: HTMLInputElement | null;
    destination: HTMLInputElement | null;
  };
}

const location_modal_state = proxy<LocationModalState>({
  open_input: null,
  input_refs: {
    origin: null,
    destination: null
  }
});

export const openLocationInput = (option: ModalOption) => {
  location_modal_state.open_input = option;
};

export const closeLocationInput = () => {
  location_modal_state.open_input = null;
};

export const useLocationModalState = () => {
  return useSnapshot(location_modal_state);
};

export const setInputRef = (
  option: ModalOption,
  inputRef: HTMLInputElement | null
) => {
  if (!option || !inputRef) {
    return;
  }
  location_modal_state.input_refs[option] = ref(inputRef);
};

export const getInputRef = (option: ModalOption) => {
  if (!option) {
    return null;
  }
  return location_modal_state.input_refs[option];
};
