import { useEffect, useState } from "react";

export const useDevTools = () => {
  const [enabled, setEnabled] = useState<boolean>(false);

  useEffect(() => {
    const url_params = new URLSearchParams(window.location.search);
    setEnabled(url_params.has("devtools"));
  }, []);

  return { enabled };
};
