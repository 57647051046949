import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SearchReduxState } from "@app/types/search-types";

const setReturnDateReducer = (
  _state: SearchReduxState["return_date"],
  action: PayloadAction<SearchReduxState["return_date"]>
) => action.payload;

const initial_state = null as SearchReduxState["return_date"];

const ReturnDate = createSlice({
  name: "search_form/return_date",
  initialState: initial_state,
  reducers: {
    setReturnDate: setReturnDateReducer
  }
});

export const { setReturnDate } = ReturnDate.actions;
export default ReturnDate.reducer;
