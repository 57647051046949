import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SearchReduxState } from "@app/types/search-types";

const setDestinationReducer = (
  _state: SearchReduxState["destination"],
  action: PayloadAction<SearchReduxState["destination"]>
) => action.payload;

const initial_state = null as SearchReduxState["destination"];

const Destination = createSlice({
  name: "search_form/destination",
  initialState: initial_state,
  reducers: {
    setDestination: setDestinationReducer
  }
});

export const { setDestination } = Destination.actions;
export default Destination.reducer;
