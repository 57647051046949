import React from "react";

import clsx from "clsx";

import { CitySuggestionItem } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-option/city-suggestion-item";
import { LocationSuggestionItem } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-option/location-suggestion-item";
import { NapiFlexLocationOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";

interface AutocompleteOptionProps extends React.HTMLAttributes<HTMLDivElement> {
  suggestion: NapiFlexLocationOption;
  is_active: boolean;
  is_recent_search?: boolean;
  handleOptionClick: (option: NapiFlexLocationOption) => void;
  handleOptionFocus: (option: NapiFlexLocationOption) => void;
}

export const AutocompleteOption = ({
  suggestion,
  is_active,
  handleOptionClick,
  handleOptionFocus,
  is_recent_search = false,
  ...props
}: AutocompleteOptionProps) => {
  const handleClick = React.useCallback(
    (_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      handleOptionClick(suggestion);
    },
    [suggestion, handleOptionClick]
  );

  const handleFocus = React.useCallback(
    (_event: React.FocusEvent<HTMLDivElement>) => {
      handleOptionFocus(suggestion);
    },
    [suggestion, handleOptionFocus]
  );

  const SuggestionItem = is_recent_search
    ? CitySuggestionItem
    : suggestion.value.template === "city"
      ? CitySuggestionItem
      : LocationSuggestionItem;

  return (
    <li aria-selected={is_active} role="option">
      <SuggestionItem
        className={clsx({
          ["!bg-color-scheme-interactive-selected-100"]: is_active
        })}
        suggestion={suggestion}
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onFocus={handleFocus}
        {...props}
      />
    </li>
  );
};
