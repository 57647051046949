import React, { ChangeEventHandler, useRef } from "react";

import { UseFormGetValues } from "react-hook-form";

import { Input } from "@busbud/horizon";

import { AutocompleteDataset } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-dataset";
import { AutocompletePredictionDatasetOptions } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-input-hydrated";
import { useAutocompleteInput } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-autocomplete-input";
import { useClickOutside } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-click-outside";
import { NapiFlexLocationOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";
import { SearchFormValues } from "@app/components/search-form-hydrated/search-form-hydrated";
import { useLiteAppContext } from "@app/helpers/hooks";
import { SearchLocationType } from "@app/types/search-types";

interface AutocompleteDropdownProps
  extends Omit<React.ComponentProps<typeof Input>, "type"> {
  type: SearchLocationType;
  autocomplete_dataset_options: AutocompletePredictionDatasetOptions;
  open: boolean;
  is_fetching: boolean;
  renderLoadingSpinner: () => React.ReactNode;
  onOptionFocus: (option: NapiFlexLocationOption) => void;
  onOptionSelect: (option: NapiFlexLocationOption) => void;
  onClose: () => void;
  getValues: UseFormGetValues<SearchFormValues>;
  value: string; // TODO: remove after prop added to `Input`
}

export const AutocompleteInputDropdown = React.forwardRef<
  HTMLInputElement,
  AutocompleteDropdownProps
>(
  (
    {
      type,
      autocomplete_dataset_options,
      open,
      onClose,
      onChange,
      value,
      is_fetching,
      renderLoadingSpinner,
      onOptionFocus,
      onOptionSelect,
      getValues,
      ...otherProps
    },
    ref
  ) => {
    const { suggestions, recent_searches } = autocomplete_dataset_options;
    const { liteTranslator } = useLiteAppContext();
    const dropdownRef = useRef<HTMLDivElement>(null);

    const has_options: boolean =
      !!suggestions.location_options.length ||
      !!recent_searches.location_options.length;
    const show_dropdown = open && (has_options || is_fetching);

    const placeholder =
      type === "origin"
        ? liteTranslator.t("!landing.input-label.origin")
        : liteTranslator.t("!landing.input-label.destination");

    useClickOutside(dropdownRef, onClose);

    const {
      handleOptionClick,
      handleOptionFocus,
      focusedOption,
      setFocusedOption
    } = useAutocompleteInput({
      location_type: type,
      onOptionSelect,
      onOptionFocus,
      getValues
    });

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = e => {
      onChange?.(e);
      setFocusedOption(null);
    };

    return (
      <div id={`${type}-dropdown`} className="h-full w-full">
        <Input
          ref={ref}
          id={`${type}-city-input`} // Used as target for `SkipLink` in `AboveTheFold`
          className="no-background-rest h-full w-full"
          autoComplete="off"
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={handleInputChange}
          {...otherProps}
        />
        {!!show_dropdown && (
          <div
            data-testid={`${type}-dropdown-container`}
            ref={dropdownRef}
            className="js-suggestions-container absolute z-[1300] w-[21rem] rounded-lg bg-color-canvas-primary pb-150 shadow-lg"
          >
            <AutocompleteDataset
              location_options={recent_searches.location_options}
              label={liteTranslator.t("!search.input.location.recent.label")}
              is_recent_search={!!recent_searches.location_options.length}
              with_footer={!!suggestions.location_options.length}
              focused_option={focusedOption}
              handleOptionClick={handleOptionClick}
              handleOptionFocus={handleOptionFocus}
            />
            <AutocompleteDataset
              location_options={suggestions.location_options}
              label={liteTranslator.t(
                "!search.input.location.suggestion.label"
              )}
              focused_option={focusedOption}
              handleOptionClick={handleOptionClick}
              handleOptionFocus={handleOptionFocus}
            />
            {renderLoadingSpinner()}
          </div>
        )}
      </div>
    );
  }
);
