import { useSelector } from "react-redux";

import { useAction } from "@app/store/hooks/useAction";
import { openModal } from "@app/store/state/modals";
import { LandingPageReduxState } from "@app/types/landing-page";
import { ResultsReduxState } from "@app/types/results-redux-types";

const getOutboundDate = (state: LandingPageReduxState | ResultsReduxState) =>
  state.search_form.outbound_date;

const getReturnDate = (state: LandingPageReduxState | ResultsReduxState) =>
  state.search_form.return_date;

export function useDateInputs() {
  const _openModal = useAction(openModal);

  const outbound_date = useSelector(getOutboundDate);
  const return_date = useSelector(getReturnDate);

  return {
    outbound_date,
    return_date,
    openSearchModal: _openModal
  };
}
