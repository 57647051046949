import React from "react";

import { AutocompleteOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-option/autocomplete-option";
import { NapiFlexLocationOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";

interface AutocompleteDatasetProps {
  location_options: NapiFlexLocationOption[];
  label: string;
  focused_option: string | null;
  is_recent_search?: boolean;
  with_footer?: boolean;
  handleOptionClick: (option: NapiFlexLocationOption) => void;
  handleOptionFocus: (option: NapiFlexLocationOption) => void;
}

export const AutocompleteDataset: React.FC<AutocompleteDatasetProps> = ({
  location_options,
  label,
  focused_option,
  is_recent_search,
  with_footer = false,
  handleOptionClick,
  handleOptionFocus
}) => {
  const has_options: boolean = location_options.length > 0;

  if (!has_options) {
    return null;
  }

  return (
    <>
      <div className="px-350 pb-100 pt-250 text-size-87 text-color-tertiary">
        {label}
      </div>
      <ul className="p-050">
        {location_options.map((option, index) => (
          <AutocompleteOption
            key={`${option.id}-${index}`}
            is_active={`${option.id}-${label}` === `${focused_option}-${label}`}
            suggestion={option}
            handleOptionClick={handleOptionClick}
            handleOptionFocus={handleOptionFocus}
            is_recent_search={is_recent_search}
          />
        ))}
      </ul>
      {!!with_footer && (
        <hr className="mx-150 mt-100" style={{ color: "#E4EBF0" }} />
      )}
    </>
  );
};
