import React, { FC, PropsWithChildren } from "react";

import clsx from "clsx";

import { Heading, type HeadingProps } from "@busbud/horizon";

type DialogSubtitleProps =
  // Pick 'as' and make it optional
  Partial<Pick<HeadingProps, "as">> &
    // Omit unnecessary props
    Omit<HeadingProps, "as" | "size">;

export const DialogSubtitle: FC<PropsWithChildren<DialogSubtitleProps>> = ({
  as = "p",
  className,
  ...restProps
}) => (
  <Heading
    as={as}
    className={clsx("mt-050 text-color-tertiary", className)}
    size="xxs"
    {...restProps}
  />
);
