import React from "react";

import { useLiteAppContext } from "@app/helpers/hooks";
import IntlProviderService from "@app/services/intl-provider";

export const useIntlProvider = () => {
  const { locale } = useLiteAppContext();
  const should_use_intl_provider = typeof Intl !== "undefined";

  const intlProvider = React.useMemo(() => {
    return should_use_intl_provider
      ? new IntlProviderService({
          locale,
          // Currency doesn't really matter here. It's required to construct valid IntlProvider though.
          // It means our IntlProvider has too many responsibilities, we're only using a few here.
          currency: "USD"
        })
      : null;
  }, [locale, should_use_intl_provider]);

  const getLocalizedDate = React.useCallback(
    (date: string): string => {
      if (!intlProvider || !date) {
        return date;
      }

      return (
        intlProvider.formatDate(date, {
          weekday: "short",
          month: "short",
          day: "numeric"
        }) || date
      );
    },
    [intlProvider]
  );

  return { getLocalizedDate };
};
