import { createStyles } from "@app/components/tss/hooks/use-tss-styles";

const selectors = {
  firstChild: ".aa-suggestion:first-of-type &",
  lastChild: ".aa-suggestion:last-child &",
  selected: ".aa-suggestion.aa-cursor &"
};

export const useSuggestionTemplateServerStyles = createStyles(({ theme }) => {
  const {
    busbud: { styles, breakpoints, colors, spacing }
  } = theme;
  const defaultSpacing = spacing(1.5);
  return {
    root: {
      borderRadius: styles.rounding.md,
      fontSize: "inherit", // override default MenuItem font size to avoid conflicts with Labels
      margin: spacing(0, -1),

      [selectors.selected]: {
        backgroundColor: colors.component.menuItem.hover.background
      },

      [breakpoints.up("sm")]: {
        borderRadius: styles.rounding.lg,
        margin: `0 ${defaultSpacing}`,

        [selectors.firstChild]: {
          marginTop: defaultSpacing
        },
        [selectors.lastChild]: {
          marginBottom: defaultSpacing
        }
      }
    },
    city: {
      padding: spacing(2, 1.5)
    },
    location: {
      padding: defaultSpacing,
      marginLeft: spacing(3),

      [breakpoints.up("sm")]: {
        marginLeft: spacing(5)
      }
    },
    textContainer: {
      marginLeft: spacing(1),
      minWidth: 0,
      maxWidth: `calc(100% - ${spacing(4)})`
    },
    locationLabel: {
      display: "block"
    },
    cityRegionLabel: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    regionLabel: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginLeft: spacing(1)
    },
    detailLabel: {
      display: "block",
      marginTop: spacing(0.5)
    }
  };
});
