import React, { useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";

import { getFormattedSuggestionName } from "@app/components/search-form/helpers";
import { placeToSuggestion } from "@app/modules/search/helpers";
import { isValidAge } from "@app/modules/search/store/selectors/passengers";
import {
  setRecentDestination,
  setRecentOrigin,
  setOrigin,
  setDestination,
  setAffiliateOptin
} from "@app/modules/search/store/slices/search-form";
import { AttributePassengerFields } from "@app/modules/search/store/slices/search-form/reducers/unassign-attribute-to-passenger";
import { LandingPageReduxState } from "@app/types/landing-page";
import { ResultsReduxState } from "@app/types/results-redux-types";
import {
  SearchPlace,
  SearchReduxState,
  SearchStoreReducers
} from "@app/types/search-types";

export type LocationStateValues = Pick<
  SearchReduxState,
  "origin" | "destination" | "recent_searches"
>;

const memoizedLocationValues = createSelector(
  [(state: LandingPageReduxState | ResultsReduxState) => state.search_form],
  (search_form): LocationStateValues => ({
    origin: search_form.origin,
    destination: search_form.destination,
    recent_searches: search_form.recent_searches
  })
);

export const useSearchLocationValues = (): LocationStateValues =>
  useSelector(memoizedLocationValues);

const getSearchLocationValues = (): LocationStateValues => {
  const state = window.store.getState() as
    | LandingPageReduxState
    | ResultsReduxState;
  return memoizedLocationValues(state);
};

const getOriginLocation = (state: SearchStoreReducers) =>
  state.search_form.origin;

export const useOriginLocation = (): SearchPlace | null =>
  useSelector(getOriginLocation);

const getAffiliateOptin = (state: SearchStoreReducers) =>
  state.search_form.affiliate_optin;

export const useAffiliateOptin = (): boolean => useSelector(getAffiliateOptin);

interface PlaceWithFormatted {
  value: SearchPlace | null;
  formatted: string;
}

interface DefaultSearchLocationValues {
  origin: PlaceWithFormatted;
  destination: PlaceWithFormatted;
}

export const useDefaultSearchLocationValues =
  (): DefaultSearchLocationValues => {
    const { origin, destination } = useSearchLocationValues();
    return React.useMemo(() => {
      const origin_place = placeToSuggestion(origin);
      const destination_place = placeToSuggestion(destination);

      return {
        origin: {
          value: origin,
          formatted: getFormattedSuggestionName(origin_place)
        },
        destination: {
          value: destination,
          formatted: getFormattedSuggestionName(destination_place)
        }
      };
    }, [origin, destination]);
  };

// TODO: move to context
export const getLegacySearchStateValues = () => {
  if (typeof window === "undefined") {
    throw new Error("This function should only be called on the client side");
  }

  const {
    BB: {
      push_state_disabled,
      search_form: {
        longitude,
        latitude,
        locale,
        recent_searches,
        discount_code
      },
      config: { lang },
      whitelabel
    },
    __GEO_DATA__
  } = window;

  return {
    push_state_disabled,
    lang,
    longitude,
    latitude,
    locale,
    recent_searches,
    geo_data: __GEO_DATA__,
    whitelabel,
    discount_code
  };
};

const searchStateActions = {
  setRecentOrigin,
  setRecentDestination,
  setOrigin,
  setDestination,
  setAffiliateOptin
} as const;

export const useSearchStateActions = () => {
  const dispatch = useDispatch();

  const search_actions = useMemo(
    () => bindActionCreators(searchStateActions, dispatch),
    [dispatch]
  );

  const { setOrigin, setDestination, setRecentOrigin, setRecentDestination } =
    search_actions;

  const swapCities = React.useCallback(() => {
    const { origin, destination, recent_searches } = getSearchLocationValues();

    setOrigin(destination);
    setDestination(origin);

    setRecentOrigin(recent_searches.destination);
    setRecentDestination(recent_searches.origin);
  }, [setOrigin, setDestination, setRecentOrigin, setRecentDestination]);

  return {
    ...search_actions,
    swapCities
  };
};

const memoizedLegacySearchFormPassengers = createSelector(
  [(state: SearchStoreReducers) => state.search_form.passengers],
  passengers => {
    const child_wheelchair_ages =
      passengers.child_wheelchair_ages.filter(isValidAge);
    const child_student_ages = passengers.child_student_ages.filter(isValidAge);

    const senior_wheelchair_ages =
      passengers.senior_wheelchair_ages.filter(isValidAge);
    const senior_student_ages =
      passengers.senior_student_ages.filter(isValidAge);

    return {
      ...passengers,
      child_wheelchair_ages,
      child_student_ages,
      senior_wheelchair_ages,
      senior_student_ages
    };
  }
);

export function useLegacySearchFormPassengers(): AttributePassengerFields {
  return useSelector(memoizedLegacySearchFormPassengers);
}
