import React from "react";

import { mergeClasses } from "@busbud/horizon";

import {
  search_form_classes,
  useSearchLayoutClasses
} from "@app/components/search-form/search-form.styles";

interface LocationGroupProps {
  one_way_only: boolean;
  originInputSection: React.ReactNode;
  destinationInputSection: React.ReactNode;
  swapLocationSection: React.ReactNode;
}

export const LocationGroup: React.FC<LocationGroupProps> = ({
  one_way_only,
  originInputSection,
  destinationInputSection,
  swapLocationSection
}) => {
  const {
    location_group_classes,
    origin_location_classes,
    destination_location_classes,
    swap_location_classes
  } = useSearchLayoutClasses(one_way_only);

  return (
    <div
      className={mergeClasses(
        ...location_group_classes,
        "lg:border-r relative flex flex-wrap max-lg:mb-100 max-lg:rounded-md max-lg:bg-color-canvas-primary max-lg:shadow-md lg:flex-nowrap lg:border-r-width-sm lg:border-r-color-primary"
      )}
    >
      <div
        className={mergeClasses(
          ...origin_location_classes,
          search_form_classes.field_mask,
          search_form_classes.cell_padding,
          "max-md:relative"
        )}
        id="origin-dropdown-wrapper"
      >
        {originInputSection}
      </div>
      <div
        className={mergeClasses(
          ...swap_location_classes,
          "absolute bottom-0 right-0 top-0 z-[2] flex items-center justify-center pl-400 pr-300 sm:static sm:self-center sm:p-0"
        )}
      >
        {swapLocationSection}
      </div>
      <div
        className={mergeClasses(
          ...destination_location_classes,
          search_form_classes.field_mask,
          search_form_classes.cell_padding,
          "max-sm:relative max-sm:border-t-width-sm max-sm:border-t-color-primary"
        )}
        id="destination-dropdown-wrapper"
      >
        {destinationInputSection}
      </div>
    </div>
  );
};
