import { getUTCDate } from "@app/utils/dates-without-moment";

export function shouldIncreaseReturnDate(
  new_outbound_date: string,
  return_date: string | null
) {
  if (!return_date) {
    return false;
  }

  const outbound_date = getUTCDate(new_outbound_date);

  return outbound_date > getUTCDate(return_date);
}
