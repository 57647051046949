import { useEffect, useRef } from "react";

export const useEventListener = <T extends keyof DocumentEventMap>(
  eventType: T,
  callback: (ev: DocumentEventMap[T]) => unknown
): void => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (document === null) {
      return;
    }
    const handler: (e: DocumentEventMap[T]) => unknown = (
      e: DocumentEventMap[T]
    ) => callbackRef.current(e);

    document.addEventListener(eventType, handler);

    return () => document.removeEventListener(eventType, handler);
  }, [eventType]);
};
