import React from "react";

import { useSeatSelectionFirst } from "@app/modules/seat-selection-first/hooks/use-seat-selection-first";

export const search_form_classes = {
  border_style: "border-color-primary border-width-sm",
  cell_padding: "sm:p-050",
  field_mask:
    "max-sm:after:content-[''] max-sm:after:w-[6rem] max-sm:after:absolute max-sm:after:top-012 max-sm:after:right-012 max-sm:after:bg-gradient-to-l max-sm:after:rounded-md max-sm:after:h-[calc(100%-4px)]"
};

type LayoutClasses =
  | "location_group"
  | "origin_location"
  | "swap_location"
  | "destination_location"
  | "date_passenger_and_search_button_group"
  | "date_and_passenger_group"
  | "date_group"
  | "outbound_date"
  | "search_button"
  | "return_date"
  | "passengers_details";

const layout_classes = {
  location_group: "w-full lg:w-10/24",
  origin_location: "w-full sm:w-10/24",
  swap_location: "sm:w-2/24",
  destination_location: "w-full sm:w-12/24",
  date_passenger_and_search_button_group: "w-full lg:w-14/24",
  date_and_passenger_group:
    "w-full sm:w-21/24 md:w-19/24 lg:w-18/24 xl:w-18/24",
  date_group: "w-full sm:w-14/24 md:w-17/24 lg:w-16/24 xl:w-14/24",
  outbound_date: "w-12/24",
  search_button: "w-full sm:w-3/24 md:w-5/24 lg:w-7/24 xl:w-6/24",
  return_date: "w-12/24",
  passengers_details: "w-full sm:w-10/24 md:9/24 xl:w-11/24"
} satisfies Record<LayoutClasses, string>;

const one_way_layout_override_classes = {
  location_group: "lg:w-10/24 xl:w-11/24",
  origin_location: "xl:w-11/24",
  swap_location: "",
  destination_location: "xl:w-11/24 lg:w-10/24",
  date_passenger_and_search_button_group: "xl:w-13/24",
  date_and_passenger_group: "sm:w-20/24 md:w-18/24 lg:w-17/24 xl:w-19/24",
  date_group: "sm:w-12/24 md:w-12/24 lg:w-10/24 xl:w-12/24",
  outbound_date: "w-full",
  search_button: "sm:w-4/24 md:w-6/24 lg:w-7/24 xl:w-5/24",
  return_date: "",
  passengers_details: "sm:w-12/24 lg:w-14/24"
} satisfies Record<LayoutClasses, string>;

const hide_passengers_picker_layout_override_classes = {
  location_group: "lg:w-11/24 xl:w-12/24",
  origin_location: "lg:w-11/24 xl:w-10/24",
  swap_location: "",
  destination_location: "lg:w-10/24",
  date_passenger_and_search_button_group: "lg:w-13/24 xl:w-12/24",
  date_and_passenger_group: "sm:w-16/24 md:w-18/24 lg:w-15/24 xl:w-16/24",
  date_group: "sm:w-full md:w-full lg:w-full xl:w-full",
  outbound_date: "",
  search_button: "sm:w-8/24 md:w-8/24 lg:w-9/24 xl:w-9/24",
  return_date: "",
  passengers_details: "sm:w-12/24 lg:w-14/24 xl:w-13/24"
} satisfies Record<LayoutClasses, string>;

export const useSearchLayoutClasses = (one_way_only: boolean) => {
  const { is_seat_selection_first_enabled } = useSeatSelectionFirst();

  return React.useMemo(() => {
    const one_way_classes = one_way_only
      ? one_way_layout_override_classes
      : null;
    const selection_first_classes = is_seat_selection_first_enabled
      ? hide_passengers_picker_layout_override_classes
      : null;

    return {
      location_group_classes: [
        layout_classes.location_group,
        one_way_classes?.location_group,
        selection_first_classes?.location_group
      ],
      origin_location_classes: [
        layout_classes.origin_location,
        one_way_classes?.origin_location,
        selection_first_classes?.origin_location
      ],
      swap_location_classes: [
        layout_classes.swap_location,
        one_way_classes?.swap_location,
        selection_first_classes?.swap_location
      ],
      destination_location_classes: [
        layout_classes.destination_location,
        one_way_classes?.destination_location,
        selection_first_classes?.destination_location
      ],
      date_passenger_and_search_button_group_classes: [
        layout_classes.date_passenger_and_search_button_group,
        one_way_classes?.date_passenger_and_search_button_group,
        selection_first_classes?.date_passenger_and_search_button_group
      ],
      date_and_passenger_group_classes: [
        layout_classes.date_and_passenger_group,
        one_way_classes?.date_and_passenger_group,
        selection_first_classes?.date_and_passenger_group
      ],
      date_group_classes: [
        layout_classes.date_group,
        one_way_classes?.date_group,
        selection_first_classes?.date_group
      ],
      outbound_date_classes: [
        layout_classes.outbound_date,
        one_way_classes?.outbound_date,
        selection_first_classes?.outbound_date
      ],
      return_date_classes: [
        layout_classes.return_date,
        one_way_classes?.return_date,
        selection_first_classes?.return_date
      ],
      passengers_details_classes: [
        layout_classes.passengers_details,
        one_way_classes?.passengers_details,
        selection_first_classes?.passengers_details
      ],
      search_button_classes: [
        layout_classes.search_button,
        one_way_classes?.search_button,
        selection_first_classes?.search_button
      ]
    };
  }, [one_way_only, is_seat_selection_first_enabled]);
};
